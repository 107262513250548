@import "../../styles/variables";

header {
  width: 100%;
  height: 70px;
  padding: 0px 30px 0px 30px;
  display: flex;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // overflow: hidden;
  // position: relative;
  // z-index: 1;

  .logo-container {
    width: 35%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }
  .navigation-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 0;
      padding: 0;
      li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-md;
        list-style-type: none;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        transition: ease-in-out 0.3s;
        margin-left: 7px;
        &:hover {
          color: $orange;
          cursor: pointer;
        }
      }
    }
  }

  .navigation-small-screen {
    width: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3 !important;

    .sandwich-button {
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 80%;
      }
    }
    .sandwich-navigator-container {
      width: 40vw;
      transition: ease-in 0.8s;
      background-color: #fff;
      position: absolute;
      left: 100%;
      white-space: nowrap;
      padding: 0 10px 0 10px;
      top: 69px;
      z-index: 2;
      ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        padding-bottom: 13px;
        li {
          font-size: $font-md;
          list-style-type: none;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          transition: ease-in-out 0.3s;
          margin-top: 25px;
          &:hover {
            color: $orange;
            cursor: pointer;
          }
        }
      }
    }

    .open {
      transform: translate(-100%, 0);
    }
  }
}
