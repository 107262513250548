@import "../../styles/variables";

@media (min-width: 1300px) {
  .calendar-screen-container {
    width: 80%;
    padding: 0px 40px 0px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .calendar-screen-title {
      font-size: 4.3em;
      margin: 0;
      color: $orange;
      font-family: "Tilt Neon", sans-serif;
      margin-top: 40px;
      text-align: center;
    }
    .calendar-main-container {
      width: 80%;
      display: flex;
      height: 550px;
      border-radius: 25px;
      margin: 50px 0px 50px 0px;
      -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      transition: 0.7s ease-in-out;
      .calendar-description {
        width: 45%;
        display: flex;
        position: relative;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-right: 1px solid rgba(134, 134, 134, 0.302);
        transition: 0.7s ease-in-out;
        .arrow-container {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid $gray;
          transition: 0.5s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            transition: 0.3s ease-in-out;
            width: 15px;
            height: 15px;
            color: $gray;
          }
          &:hover svg {
            color: $orange;
          }
          &:hover {
            border-color: $orange;
          }
        }

        .description-logo-container {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          img {
            width: 80%;
          }
        }

        .description-texts-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-left: 20px;
          box-sizing: border-box;
          .description-domain {
            margin: 0;
            margin-top: 20px;
            font-size: $font-md;
            color: $orange;
            font-family: "Inter", sans-serif;
            font-weight: 600;
          }
          .description-text {
            width: 90%;
            color: $gray;
            margin: 0;
            margin-top: 30px;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
          }
          .appointment-details {
            width: 95%;
            display: flex;
            flex-direction: column;
            gap: 14px;
            height: fit-content;
            margin-top: 20px;
            .detail-container {
              width: 100%;

              display: flex;
              align-items: center;
              gap: 10px;
              .detail-icon {
                svg {
                  font-size: $font-lg;
                  color: $gray;
                  margin: 0;
                }
              }
              .detail-text {
                font-size: $font-md;
                color: $gray;
                font-family: "Inter", sans-serif;
                margin: 0;
                span {
                  color: $orange;
                }
              }
            }
          }
        }
      }
      .calendar-and-slot-container {
        display: flex;
        width: 60%;
        .calendar-active {
          width: 70% !important;
        }
        .calendar-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 0.7s ease-in-out;

          .react-calendar {
            width: 90% !important;
            border: 0px !important;
            padding: 5px;
            box-sizing: border-box;
            .react-calendar__navigation {
              justify-content: space-between;
              :nth-child(2) {
                order: 2;
              }
              :nth-child(3) {
                order: 0;
              }
              :nth-child(4) {
                order: 3;
              }
            }

            .react-calendar__navigation__label {
              flex-grow: 1 !important;
              text-align: start;
              font-size: $font-md;
              font-weight: 400;
              pointer-events: none;
            }
            .react-calendar__navigation__label__labelText {
              font-weight: 600;
              font-size: $font-md;
            }
            .react-calendar__navigation__arrow {
              font-size: 30px;
              color: $orange;
              border-radius: 50%;
              text-align: center;
              width: 40px !important;
              height: 40px !important;
              padding: 0 !important;
              min-width: 0 !important;
            }
            .react-calendar__navigation__arrow:disabled {
              font-size: 30px;
              color: rgba(26, 26, 26, 0.61);
              background-color: #ffffff;
            }
            .react-calendar__navigation__arrow:hover {
              background: rgba(255, 159, 104, 0.3) !important;
              transition: 0.22s ease-in-out;
            }
            .react-calendar__navigation__arrow:disabled:hover {
              background: #ffffff !important;
              transition: 0.22s ease-in-out;
            }

            .react-calendar__navigation__arrow:enabled:focus {
              outline: none;
            }

            .react-calendar__navigation__arrow:focus {
              outline: none;
              background-color: #fff;
            }
            .react-calendar__navigation__prev2-button {
              display: none;
            }
            .react-calendar__navigation__next2-button {
              display: none;
            }
            .react-calendar__month-view__weekdays {
              text-align: center;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 12px;
              color: rgba(26, 26, 26, 0.61);
              abbr {
                text-decoration: none;
              }
            }
            .react-calendar__month-view__days {
              * {
                margin-top: 5px;
              }
              .react-calendar__month-view__days__day {
                flex: 0 0 13.5% !important;
                margin-left: 0.72857%;
                position: relative;
                abbr {
                  position: absolute;
                  top: 40%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .react-calendar__month-view__days__day--weekend {
              color: black;
            }
            .react-calendar__tile {
              padding-top: 11%;
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .available {
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now {
              background-color: rgba(255, 159, 104, 0.3);
              color: rgba(26, 26, 26, 0.61);
              box-sizing: border-box;
              position: relative;
              font-weight: 500;
              transition: 0.22 ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: rgba(26, 26, 26, 0.61);
              position: absolute;
              top: 75%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .react-calendar__tile:disabled {
              background-color: #fff;
              color: rgb(174, 174, 174);
              font-weight: 500;
            }

            .react-calendar__tile--now:enabled:hover,
            .react-calendar__tile--now:enabled:focus {
              background: rgba(255, 159, 104, 0.6);
            }
            .react-calendar__tile--active {
              background: $orange;
              color: white;
            }
            .react-calendar__tile--active:enabled:hover,
            .react-calendar__tile--active:enabled:focus {
              background: $orange;
              font-weight: 600;
            }
          }
        }
        .calendar-timeslots-active {
          width: 30% !important;
        }
        .calendar-timeslots-container {
          width: 0px;
          display: none;
          height: 100%;
          transition: 0.7s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .calendar-timeslots-date-container {
            height: 15%;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;
            .calendar-timeslots-date {
              width: 100%;
              transition: 0.7s ease-in-out;
              margin: 0;
              position: relative;
              overflow: hidden;
              display: inline-block;
              ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
              }
            }
          }

          .timeslots {
            width: 100%;
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            align-items: center;
            margin-bottom: 10px;
            .notimeslot-text {
              text-align: center;
            }
          }
        }
      }

      .appointment-information-container {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 40px;
        box-sizing: border-box;
        overflow-y: scroll;
        .appointment-information-title {
          font-size: 2.1em;
          font-family: "Inter", sans-serif;
          color: $gray;
          margin: 0;
        }

        .name-inputs {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          .name-input-container {
            display: flex;
            flex-direction: column;
            .input-label {
              font-family: "Inter", sans-serif;
              font-size: $font-sm;
              font-weight: 600;
            }
            .name-input {
              width: 200px;
              height: 20px;
              padding: 10px;
              border-radius: 10px;
              outline: none;
              border: 1px solid rgba(26, 26, 26, 0.61);
              font-size: $font-md;
              margin-top: 10px;
            }
            .error-message {
              color: red;
              font-size: $font-sm;
              font-weight: 600;
              margin-top: 6px;
            }
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .input {
            height: 20px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .moreabout-input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .moreabout-input {
            height: 70px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
        }

        .calendar-services-title {
          font-size: $font-sm;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          margin-top: 15px;
        }
        .calendar-services-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          row-gap: 15px;
        }

        .file-input-container {
          margin-top: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 1px solid rgba(128, 128, 128, 0.316);
          border-bottom: 1px solid rgba(128, 128, 128, 0.316);

          .file-input-label {
            width: fit-content;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            padding: 7px 15px 7px 15px;
            border-radius: 15px;
            background-color: $orange;
            color: #fff;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: $beige;
              color: $orange;
            }
          }
          .file-upload-instruction {
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            color: $gray;
            margin: 0;
            margin-top: 3px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }

          .all-files-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            height: fit-content;
            margin-top: 10px;
            .file-container {
              width: 20%;
              height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              border-radius: 15px;
              overflow: hidden;
              .file-image {
                width: 100%;
                opacity: 0.6;
              }
              .delete {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translate(-100%, 0);
                cursor: pointer;
                svg {
                  transition: 0.3s ease-in-out;
                }
                &:hover svg {
                  transform: scale(1.1);
                }
              }
            }
          }
        }

        .form-button {
          width: 220px;
          height: 50px;
          padding: 10px 20px 10px 20px;
          outline: none;
          border-radius: 30px;
          background-color: #ff9f688c;
          border: none;
          color: #ffffff;
          font-size: $font-md;
          font-weight: 600;
          margin-top: 60px;
          transition: 0.4s ease-in-out;
        }
        .valid {
          background-color: $orange;
          cursor: pointer;
        }
      }
    }
  }
}

//////////////////////////////////////// LG

@media (min-width: 1150px) and (max-width: 1299px) {
  .calendar-screen-container {
    width: 90%;
    padding: 0px 40px 0px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .calendar-screen-title {
      font-size: 4.3em;
      margin: 0;
      color: $orange;
      font-family: "Tilt Neon", sans-serif;
      margin-top: 40px;
      text-align: center;
    }
    .calendar-main-container {
      width: 80%;
      display: flex;
      height: 550px;
      border-radius: 25px;
      margin: 50px 0px 50px 0px;
      -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      transition: 0.7s ease-in-out;
      .calendar-description {
        width: 45%;
        display: flex;
        position: relative;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-right: 1px solid rgba(134, 134, 134, 0.302);
        transition: 0.7s ease-in-out;
        .arrow-container {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid $gray;
          transition: 0.5s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            transition: 0.3s ease-in-out;
            width: 15px;
            height: 15px;
            color: $gray;
          }
          &:hover svg {
            color: $orange;
          }
          &:hover {
            border-color: $orange;
          }
        }

        .description-logo-container {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          img {
            width: 80%;
          }
        }

        .description-texts-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-left: 20px;
          box-sizing: border-box;
          .description-domain {
            margin: 0;
            margin-top: 20px;
            font-size: $font-md;
            color: $orange;
            font-family: "Inter", sans-serif;
            font-weight: 600;
          }
          .description-text {
            width: 90%;
            color: $gray;
            margin: 0;
            margin-top: 30px;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
          }
          .appointment-details {
            width: 95%;
            display: flex;
            flex-direction: column;
            gap: 14px;
            height: fit-content;
            margin-top: 20px;
            .detail-container {
              width: 100%;

              display: flex;
              align-items: center;
              gap: 10px;
              .detail-icon {
                svg {
                  font-size: $font-lg;
                  color: $gray;
                  margin: 0;
                }
              }
              .detail-text {
                font-size: $font-md;
                color: $gray;
                font-family: "Inter", sans-serif;
                margin: 0;
                span {
                  color: $orange;
                }
              }
            }
          }
        }
      }
      .calendar-and-slot-container {
        display: flex;
        width: 60%;
        .calendar-active {
          width: 70% !important;
        }
        .calendar-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 0.7s ease-in-out;

          .react-calendar {
            width: 90% !important;
            border: 0px !important;
            padding: 5px;
            box-sizing: border-box;
            .react-calendar__navigation {
              justify-content: space-between;
              :nth-child(2) {
                order: 2;
              }
              :nth-child(3) {
                order: 0;
              }
              :nth-child(4) {
                order: 3;
              }
            }

            .react-calendar__navigation__label {
              flex-grow: 1 !important;
              text-align: start;
              font-size: $font-md;
              font-weight: 400;
              pointer-events: none;
            }
            .react-calendar__navigation__label__labelText {
              font-weight: 600;
              font-size: $font-md;
            }
            .react-calendar__navigation__arrow {
              font-size: 30px;
              color: $orange;
              border-radius: 50%;
              text-align: center;
              width: 40px !important;
              height: 40px !important;
              padding: 0 !important;
              min-width: 0 !important;
            }
            .react-calendar__navigation__arrow:disabled {
              font-size: 30px;
              color: rgba(26, 26, 26, 0.61);
              background-color: #ffffff;
            }
            .react-calendar__navigation__arrow:hover {
              background: rgba(255, 159, 104, 0.3) !important;
              transition: 0.22s ease-in-out;
            }
            .react-calendar__navigation__arrow:disabled:hover {
              background: #ffffff !important;
              transition: 0.22s ease-in-out;
            }

            .react-calendar__navigation__arrow:enabled:focus {
              outline: none;
            }

            .react-calendar__navigation__arrow:focus {
              outline: none;
              background-color: #fff;
            }
            .react-calendar__navigation__prev2-button {
              display: none;
            }
            .react-calendar__navigation__next2-button {
              display: none;
            }
            .react-calendar__month-view__weekdays {
              text-align: center;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 12px;
              color: rgba(26, 26, 26, 0.61);
              abbr {
                text-decoration: none;
              }
            }
            .react-calendar__month-view__days {
              * {
                margin-top: 5px;
              }
              .react-calendar__month-view__days__day {
                flex: 0 0 13.5% !important;
                margin-left: 0.72857%;
                position: relative;
                abbr {
                  position: absolute;
                  top: 40%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .react-calendar__month-view__days__day--weekend {
              color: black;
            }
            .react-calendar__tile {
              padding-top: 11%;
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .available {
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now {
              background-color: rgba(255, 159, 104, 0.3);
              color: rgba(26, 26, 26, 0.61);
              box-sizing: border-box;
              position: relative;
              font-weight: 500;
              transition: 0.22 ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: rgba(26, 26, 26, 0.61);
              position: absolute;
              top: 75%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .react-calendar__tile:disabled {
              background-color: #fff;
              color: rgb(174, 174, 174);
              font-weight: 500;
            }

            .react-calendar__tile--now:enabled:hover,
            .react-calendar__tile--now:enabled:focus {
              background: rgba(255, 159, 104, 0.6);
            }
            .react-calendar__tile--active {
              background: $orange;
              color: white;
            }
            .react-calendar__tile--active:enabled:hover,
            .react-calendar__tile--active:enabled:focus {
              background: $orange;
              font-weight: 600;
            }
          }
        }

        .calendar-timeslots-active {
          width: 30% !important;
        }
        .calendar-timeslots-container {
          width: 0px;
          display: none;
          height: 100%;
          transition: 0.7s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .calendar-timeslots-date-container {
            height: 15%;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;
            .calendar-timeslots-date {
              width: 100%;
              transition: 0.7s ease-in-out;
              margin: 0;
              position: relative;
              overflow: hidden;
              display: inline-block;
              ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
              }
            }
          }

          .timeslots {
            width: 100%;
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            align-items: center;
            margin-bottom: 10px;
            .notimeslot-text {
              text-align: center;
            }
          }
        }
      }

      .appointment-information-container {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 40px;
        box-sizing: border-box;
        overflow-y: scroll;
        .appointment-information-title {
          font-size: 2.1em;
          font-family: "Inter", sans-serif;
          color: $gray;
          margin: 0;
        }

        .name-inputs {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          .name-input-container {
            display: flex;
            flex-direction: column;
            .input-label {
              font-family: "Inter", sans-serif;
              font-size: $font-sm;
              font-weight: 600;
            }
            .name-input {
              width: 200px;
              height: 20px;
              padding: 10px;
              border-radius: 10px;
              outline: none;
              border: 1px solid rgba(26, 26, 26, 0.61);
              font-size: $font-md;
              margin-top: 10px;
            }
            .error-message {
              color: red;
              font-size: $font-sm;
              font-weight: 600;
              margin-top: 6px;
            }
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .input {
            height: 20px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .moreabout-input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .moreabout-input {
            height: 70px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
        }

        .calendar-services-title {
          font-size: $font-sm;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          margin-top: 15px;
        }
        .calendar-services-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          row-gap: 15px;
        }

        .file-input-container {
          margin-top: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 1px solid rgba(128, 128, 128, 0.316);
          border-bottom: 1px solid rgba(128, 128, 128, 0.316);

          .file-input-label {
            width: fit-content;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            padding: 7px 15px 7px 15px;
            border-radius: 15px;
            background-color: $orange;
            color: #fff;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: $beige;
              color: $orange;
            }
          }
          .file-upload-instruction {
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            color: $gray;
            margin: 0;
            margin-top: 3px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }

          .all-files-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            height: fit-content;
            margin-top: 10px;
            .file-container {
              width: 20%;
              height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              border-radius: 15px;
              overflow: hidden;
              .file-image {
                width: 100%;
                opacity: 0.6;
              }
              .delete {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translate(-100%, 0);
                cursor: pointer;
                svg {
                  transition: 0.3s ease-in-out;
                }
                &:hover svg {
                  transform: scale(1.1);
                }
              }
            }
          }
        }

        .form-button {
          width: 220px;
          height: 50px;
          padding: 10px 20px 10px 20px;
          outline: none;
          border-radius: 30px;
          background-color: #ff9f688c;
          border: none;
          color: #ffffff;
          font-size: $font-md;
          font-weight: 600;
          margin-top: 60px;
          transition: 0.4s ease-in-out;
        }
        .valid {
          background-color: $orange;
          cursor: pointer;
        }
      }
    }
  }
}

//////////////////////////////////////// MD

@media (min-width: 1025px) and (max-width: 1149px) {
  .calendar-screen-container {
    width: 95%;
    padding: 0px 40px 0px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .calendar-screen-title {
      font-size: 4.3em;
      margin: 0;
      color: $orange;
      font-family: "Tilt Neon", sans-serif;
      margin-top: 40px;
      text-align: center;
    }
    .calendar-main-container {
      width: 90%;
      display: flex;
      height: 550px;
      border-radius: 25px;
      margin: 50px 0px 50px 0px;
      -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      transition: 0.7s ease-in-out;
      .calendar-description {
        width: 45%;
        display: flex;
        position: relative;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-right: 1px solid rgba(134, 134, 134, 0.302);
        transition: 0.7s ease-in-out;
        .arrow-container {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid $gray;
          transition: 0.5s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            transition: 0.3s ease-in-out;
            width: 15px;
            height: 15px;
            color: $gray;
          }
          &:hover svg {
            color: $orange;
          }
          &:hover {
            border-color: $orange;
          }
        }

        .description-logo-container {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          img {
            width: 80%;
          }
        }

        .description-texts-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-left: 20px;
          box-sizing: border-box;
          .description-domain {
            margin: 0;
            margin-top: 20px;
            font-size: $font-md;
            color: $orange;
            font-family: "Inter", sans-serif;
            font-weight: 600;
          }
          .description-text {
            width: 90%;
            color: $gray;
            margin: 0;
            margin-top: 30px;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
          }
          .appointment-details {
            width: 95%;
            display: flex;
            flex-direction: column;
            gap: 14px;
            height: fit-content;
            margin-top: 20px;
            .detail-container {
              width: 100%;

              display: flex;
              align-items: center;
              gap: 10px;
              .detail-icon {
                svg {
                  font-size: $font-lg;
                  color: $gray;
                  margin: 0;
                }
              }
              .detail-text {
                font-size: $font-md;
                color: $gray;
                font-family: "Inter", sans-serif;
                margin: 0;
                span {
                  color: $orange;
                }
              }
            }
          }
        }
      }
      .calendar-and-slot-container {
        display: flex;
        width: 60%;
        .calendar-active {
          width: 70% !important;
        }
        .calendar-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 0.7s ease-in-out;

          .react-calendar {
            width: 90% !important;
            border: 0px !important;
            padding: 5px;
            box-sizing: border-box;
            .react-calendar__navigation {
              justify-content: space-between;
              :nth-child(2) {
                order: 2;
              }
              :nth-child(3) {
                order: 0;
              }
              :nth-child(4) {
                order: 3;
              }
            }

            .react-calendar__navigation__label {
              flex-grow: 1 !important;
              text-align: start;
              font-size: $font-md;
              font-weight: 400;
              pointer-events: none;
            }
            .react-calendar__navigation__label__labelText {
              font-weight: 600;
              font-size: $font-md;
            }
            .react-calendar__navigation__arrow {
              font-size: 30px;
              color: $orange;
              border-radius: 50%;
              text-align: center;
              width: 40px !important;
              height: 40px !important;
              padding: 0 !important;
              min-width: 0 !important;
            }
            .react-calendar__navigation__arrow:disabled {
              font-size: 30px;
              color: rgba(26, 26, 26, 0.61);
              background-color: #ffffff;
            }
            .react-calendar__navigation__arrow:hover {
              background: rgba(255, 159, 104, 0.3) !important;
              transition: 0.22s ease-in-out;
            }
            .react-calendar__navigation__arrow:disabled:hover {
              background: #ffffff !important;
              transition: 0.22s ease-in-out;
            }

            .react-calendar__navigation__arrow:enabled:focus {
              outline: none;
            }

            .react-calendar__navigation__arrow:focus {
              outline: none;
              background-color: #fff;
            }
            .react-calendar__navigation__prev2-button {
              display: none;
            }
            .react-calendar__navigation__next2-button {
              display: none;
            }
            .react-calendar__month-view__weekdays {
              text-align: center;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 12px;
              color: rgba(26, 26, 26, 0.61);
              abbr {
                text-decoration: none;
              }
            }
            .react-calendar__month-view__days {
              * {
                margin-top: 5px;
              }
              .react-calendar__month-view__days__day {
                flex: 0 0 13.5% !important;
                margin-left: 0.72857%;
                position: relative;
                abbr {
                  position: absolute;
                  top: 40%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .react-calendar__month-view__days__day--weekend {
              color: black;
            }
            .react-calendar__tile {
              padding-top: 11%;
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .available {
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now {
              background-color: rgba(255, 159, 104, 0.3);
              color: rgba(26, 26, 26, 0.61);
              box-sizing: border-box;
              position: relative;
              font-weight: 500;
              transition: 0.22 ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: rgba(26, 26, 26, 0.61);
              position: absolute;
              top: 75%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .react-calendar__tile:disabled {
              background-color: #fff;
              color: rgb(174, 174, 174);
              font-weight: 500;
            }

            .react-calendar__tile--now:enabled:hover,
            .react-calendar__tile--now:enabled:focus {
              background: rgba(255, 159, 104, 0.6);
            }
            .react-calendar__tile--active {
              background: $orange;
              color: white;
            }
            .react-calendar__tile--active:enabled:hover,
            .react-calendar__tile--active:enabled:focus {
              background: $orange;
              font-weight: 600;
            }
          }
        }
        .calendar-timeslots-active {
          width: 30% !important;
        }
        .calendar-timeslots-container {
          width: 0px;
          display: none;
          height: 100%;
          transition: 0.7s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .calendar-timeslots-date-container {
            height: 15%;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;
            .calendar-timeslots-date {
              width: 100%;
              transition: 0.7s ease-in-out;
              margin: 0;
              position: relative;
              overflow: hidden;
              display: inline-block;
              ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
              }
            }
          }

          .timeslots {
            width: 100%;
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            align-items: center;
            margin-bottom: 10px;
            .notimeslot-text {
              text-align: center;
            }
          }
        }
      }
      .appointment-information-container {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 40px;
        box-sizing: border-box;
        overflow-y: scroll;
        .appointment-information-title {
          font-size: 2.1em;
          font-family: "Inter", sans-serif;
          color: $gray;
          margin: 0;
        }

        .name-inputs {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          .name-input-container {
            display: flex;
            flex-direction: column;
            .input-label {
              font-family: "Inter", sans-serif;
              font-size: $font-sm;
              font-weight: 600;
            }
            .name-input {
              width: 200px;
              height: 20px;
              padding: 10px;
              border-radius: 10px;
              outline: none;
              border: 1px solid rgba(26, 26, 26, 0.61);
              font-size: $font-md;
              margin-top: 10px;
            }
            .error-message {
              color: red;
              font-size: $font-sm;
              font-weight: 600;
              margin-top: 6px;
            }
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .input {
            height: 20px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .moreabout-input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .moreabout-input {
            height: 70px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
        }

        .calendar-services-title {
          font-size: $font-sm;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          margin-top: 15px;
        }
        .calendar-services-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          row-gap: 15px;
        }

        .file-input-container {
          margin-top: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 1px solid rgba(128, 128, 128, 0.316);
          border-bottom: 1px solid rgba(128, 128, 128, 0.316);

          .file-input-label {
            width: fit-content;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            padding: 7px 15px 7px 15px;
            border-radius: 15px;
            background-color: $orange;
            color: #fff;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: $beige;
              color: $orange;
            }
          }
          .file-upload-instruction {
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            color: $gray;
            margin: 0;
            margin-top: 3px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }

          .all-files-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            height: fit-content;
            margin-top: 10px;
            .file-container {
              width: 20%;
              height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              border-radius: 15px;
              overflow: hidden;
              .file-image {
                width: 100%;
                opacity: 0.6;
              }
              .delete {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translate(-100%, 0);
                cursor: pointer;
                svg {
                  transition: 0.3s ease-in-out;
                }
                &:hover svg {
                  transform: scale(1.1);
                }
              }
            }
          }
        }

        .form-button {
          width: 220px;
          height: 50px;
          padding: 10px 20px 10px 20px;
          outline: none;
          border-radius: 30px;
          background-color: #ff9f688c;
          border: none;
          color: #ffffff;
          font-size: $font-md;
          font-weight: 600;
          margin-top: 60px;
          transition: 0.4s ease-in-out;
        }
        .valid {
          background-color: $orange;
          cursor: pointer;
        }
      }
    }
  }
}

//////////////////////////////////////// SM

@media (min-width: 768px) and (max-width: 1024px) {
  .calendar-screen-container {
    width: 100%;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .calendar-screen-title {
      font-size: 4.3em;
      width: 90%;
      margin: 0;
      color: $orange;
      font-family: "Tilt Neon", sans-serif;
      margin-top: 40px;
      text-align: center;
    }
    .calendar-main-container {
      width: 90%;
      display: flex;
      height: 550px;
      border-radius: 25px;
      margin: 50px 0px 50px 0px;
      -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      transition: 0.7s ease-in-out;
      .calendar-description {
        width: 45%;
        display: flex;
        position: relative;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-right: 1px solid rgba(134, 134, 134, 0.302);
        transition: 0.7s ease-in-out;
        .arrow-container {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid $gray;
          transition: 0.5s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            transition: 0.3s ease-in-out;
            width: 15px;
            height: 15px;
            color: $gray;
          }
          &:hover svg {
            color: $orange;
          }
          &:hover {
            border-color: $orange;
          }
        }

        .description-logo-container {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          img {
            width: 80%;
          }
        }

        .description-texts-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-left: 20px;
          box-sizing: border-box;
          .description-domain {
            margin: 0;
            margin-top: 20px;
            font-size: $font-md;
            color: $orange;
            font-family: "Inter", sans-serif;
            font-weight: 600;
          }
          .description-text {
            width: 90%;
            color: $gray;
            margin: 0;
            margin-top: 30px;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
          }
          .appointment-details {
            width: 95%;
            display: flex;
            flex-direction: column;
            gap: 14px;
            height: fit-content;
            margin-top: 20px;
            .detail-container {
              width: 100%;

              display: flex;
              align-items: center;
              gap: 10px;
              .detail-icon {
                svg {
                  font-size: $font-lg;
                  color: $gray;
                  margin: 0;
                }
              }
              .detail-text {
                font-size: $font-md;
                color: $gray;
                font-family: "Inter", sans-serif;
                margin: 0;
                span {
                  color: $orange;
                }
              }
            }
          }
        }
      }
      .calendar-and-slot-container {
        display: flex;
        width: 60%;
        .calendar-active {
          width: 70% !important;
        }
        .calendar-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 0.7s ease-in-out;

          .react-calendar {
            width: 90% !important;
            border: 0px !important;
            padding: 5px;
            box-sizing: border-box;
            .react-calendar__navigation {
              justify-content: space-between;
              :nth-child(2) {
                order: 2;
              }
              :nth-child(3) {
                order: 0;
              }
              :nth-child(4) {
                order: 3;
              }
            }

            .react-calendar__navigation__label {
              flex-grow: 1 !important;
              text-align: start;
              font-size: $font-md;
              font-weight: 400;
              pointer-events: none;
            }
            .react-calendar__navigation__label__labelText {
              font-weight: 600;
              font-size: $font-md;
            }
            .react-calendar__navigation__arrow {
              font-size: 30px;
              color: $orange;
              border-radius: 50%;
              text-align: center;
              width: 40px !important;
              height: 40px !important;
              padding: 0 !important;
              min-width: 0 !important;
            }
            .react-calendar__navigation__arrow:disabled {
              font-size: 30px;
              color: rgba(26, 26, 26, 0.61);
              background-color: #ffffff;
            }
            .react-calendar__navigation__arrow:hover {
              background: rgba(255, 159, 104, 0.3) !important;
              transition: 0.22s ease-in-out;
            }
            .react-calendar__navigation__arrow:disabled:hover {
              background: #ffffff !important;
              transition: 0.22s ease-in-out;
            }

            .react-calendar__navigation__arrow:enabled:focus {
              outline: none;
            }

            .react-calendar__navigation__arrow:focus {
              outline: none;
              background-color: #fff;
            }
            .react-calendar__navigation__prev2-button {
              display: none;
            }
            .react-calendar__navigation__next2-button {
              display: none;
            }
            .react-calendar__month-view__weekdays {
              text-align: center;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 12px;
              color: rgba(26, 26, 26, 0.61);
              abbr {
                text-decoration: none;
              }
            }
            .react-calendar__month-view__days {
              * {
                margin-top: 5px;
              }
              .react-calendar__month-view__days__day {
                flex: 0 0 13.5% !important;
                margin-left: 0.72857%;
                position: relative;
                abbr {
                  position: absolute;
                  top: 40%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .react-calendar__month-view__days__day--weekend {
              color: black;
            }
            .react-calendar__tile {
              padding-top: 11%;
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .available {
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now {
              background-color: rgba(255, 159, 104, 0.3);
              color: rgba(26, 26, 26, 0.61);
              box-sizing: border-box;
              position: relative;
              font-weight: 500;
              transition: 0.22 ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: rgba(26, 26, 26, 0.61);
              position: absolute;
              top: 75%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .react-calendar__tile:disabled {
              background-color: #fff;
              color: rgb(174, 174, 174);
              font-weight: 500;
            }

            .react-calendar__tile--now:enabled:hover,
            .react-calendar__tile--now:enabled:focus {
              background: rgba(255, 159, 104, 0.6);
            }
            .react-calendar__tile--active {
              background: $orange;
              color: white;
            }
            .react-calendar__tile--active:enabled:hover,
            .react-calendar__tile--active:enabled:focus {
              background: $orange;
              font-weight: 600;
            }
          }
        }
        .calendar-timeslots-active {
          width: 30% !important;
        }
        .calendar-timeslots-container {
          width: 0px;
          display: none;
          height: 100%;
          transition: 0.7s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .calendar-timeslots-date-container {
            height: 15%;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;
            .calendar-timeslots-date {
              width: 100%;
              transition: 0.7s ease-in-out;
              margin: 0;
              position: relative;
              overflow: hidden;
              display: inline-block;
              ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
              }
            }
          }

          .timeslots {
            width: 100%;
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            align-items: center;
            margin-bottom: 10px;
            .notimeslot-text {
              text-align: center;
            }
          }
        }
      }

      .appointment-information-container {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 40px;
        box-sizing: border-box;
        overflow-y: scroll;
        .appointment-information-title {
          font-size: 2.1em;
          font-family: "Inter", sans-serif;
          color: $gray;
          margin: 0;
        }

        .name-inputs {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          .name-input-container {
            display: flex;
            flex-direction: column;
            .input-label {
              font-family: "Inter", sans-serif;
              font-size: $font-sm;
              font-weight: 600;
            }
            .name-input {
              width: 140px;
              height: 20px;
              padding: 10px;
              border-radius: 10px;
              outline: none;
              border: 1px solid rgba(26, 26, 26, 0.61);
              font-size: $font-md;
              margin-top: 10px;
            }
            .error-message {
              color: red;
              font-size: $font-sm;
              font-weight: 600;
              margin-top: 6px;
            }
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .input {
            height: 20px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .moreabout-input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .moreabout-input {
            height: 70px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
        }

        .calendar-services-title {
          font-size: $font-sm;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          margin-top: 15px;
        }
        .calendar-services-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          row-gap: 15px;
        }

        .file-input-container {
          margin-top: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 1px solid rgba(128, 128, 128, 0.316);
          border-bottom: 1px solid rgba(128, 128, 128, 0.316);

          .file-input-label {
            width: fit-content;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            padding: 7px 15px 7px 15px;
            border-radius: 15px;
            background-color: $orange;
            color: #fff;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: $beige;
              color: $orange;
            }
          }
          .file-upload-instruction {
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            color: $gray;
            margin: 0;
            margin-top: 3px;
          }

          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }

          .all-files-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            height: fit-content;
            margin-top: 10px;
            .file-container {
              width: 20%;
              height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              border-radius: 15px;
              overflow: hidden;
              .file-image {
                width: 100%;
                opacity: 0.6;
              }
              .delete {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translate(-100%, 0);
                cursor: pointer;
                svg {
                  transition: 0.3s ease-in-out;
                }
                &:hover svg {
                  transform: scale(1.1);
                }
              }
            }
          }
        }

        .form-button {
          width: 220px;
          height: 50px;
          padding: 10px 20px 10px 20px;
          outline: none;
          border-radius: 30px;
          background-color: #ff9f688c;
          border: none;
          color: #ffffff;
          font-size: $font-md;
          font-weight: 600;
          margin-top: 60px;
          transition: 0.4s ease-in-out;
        }
        .valid {
          background-color: $orange;
          cursor: pointer;
        }
      }
    }
  }
}

//////////////////////////////////////// XS

@media (min-width: 576px) and (max-width: 767px) {
  .calendar-screen-container {
    width: 100%;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .calendar-screen-title {
      font-size: 4.3em;
      width: 90%;
      margin: 0;
      color: $orange;
      font-family: "Tilt Neon", sans-serif;
      margin-top: 40px;
      text-align: center;
    }
    .calendar-main-container {
      width: 80%;
      display: flex;
      flex-direction: column;
      min-height: 550px;
      border-radius: 25px;
      margin: 50px 0px 50px 0px;
      -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      transition: 0.7s ease-in-out;
      .calendar-description {
        width: 100% !important;
        display: flex;
        position: relative;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // border-right: 1px solid rgba(134, 134, 134, 0.302);
        transition: 0.7s ease-in-out;
        .arrow-container {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid $gray;
          transition: 0.5s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            transition: 0.3s ease-in-out;
            width: 15px;
            height: 15px;
            color: $gray;
          }
          &:hover svg {
            color: $orange;
          }
          &:hover {
            border-color: $orange;
          }
        }

        .description-logo-container {
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          img {
            width: 80%;
          }
        }

        .description-texts-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding-left: 20px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          padding-bottom: 15px;
          .description-domain {
            margin: 0;
            margin-top: 10px;
            font-size: $font-md;
            color: $orange;
            font-family: "Inter", sans-serif;
            font-weight: 600;
          }
          .description-text {
            width: 90%;
            color: $gray;
            margin: 0;
            margin-top: 10px;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            text-align: center;
          }
          .appointment-details {
            width: 95%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px;
            height: fit-content;
            margin-top: 20px;
            .detail-container {
              width: fit-content;

              display: flex;
              align-items: center;
              gap: 7px;
              .detail-icon {
                svg {
                  font-size: $font-lg;
                  color: $gray;
                  margin: 0;
                }
              }
              .detail-text {
                font-size: $font-md;
                color: $gray;
                font-family: "Inter", sans-serif;
                margin: 0;
                span {
                  color: $orange;
                }
              }
            }
          }
        }
      }
      .calendar-and-slot-container {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 500px;
        .calendar-active {
          width: 70% !important;
        }
        .calendar-container {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 0.7s ease-in-out;

          .react-calendar {
            width: 90% !important;
            border: 0px !important;
            padding: 5px;
            box-sizing: border-box;
            .react-calendar__navigation {
              justify-content: space-between;
              :nth-child(2) {
                order: 2;
              }
              :nth-child(3) {
                order: 0;
              }
              :nth-child(4) {
                order: 3;
              }
            }

            .react-calendar__navigation__label {
              flex-grow: 1 !important;
              text-align: start;
              font-size: $font-md;
              font-weight: 400;
              pointer-events: none;
            }
            .react-calendar__navigation__label__labelText {
              font-weight: 600;
              font-size: $font-md;
            }
            .react-calendar__navigation__arrow {
              font-size: 30px;
              color: $orange;
              border-radius: 50%;
              text-align: center;
              width: 40px !important;
              height: 40px !important;
              padding: 0 !important;
              min-width: 0 !important;
            }
            .react-calendar__navigation__arrow:disabled {
              font-size: 30px;
              color: rgba(26, 26, 26, 0.61);
              background-color: #ffffff;
            }
            .react-calendar__navigation__arrow:hover {
              background: rgba(255, 159, 104, 0.3) !important;
              transition: 0.22s ease-in-out;
            }
            .react-calendar__navigation__arrow:disabled:hover {
              background: #ffffff !important;
              transition: 0.22s ease-in-out;
            }

            .react-calendar__navigation__arrow:enabled:focus {
              outline: none;
            }

            .react-calendar__navigation__arrow:focus {
              outline: none;
              background-color: #fff;
            }
            .react-calendar__navigation__prev2-button {
              display: none;
            }
            .react-calendar__navigation__next2-button {
              display: none;
            }
            .react-calendar__month-view__weekdays {
              text-align: center;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 12px;
              color: rgba(26, 26, 26, 0.61);
              abbr {
                text-decoration: none;
              }
            }
            .react-calendar__month-view__days {
              * {
                margin-top: 5px;
              }
              .react-calendar__month-view__days__day {
                flex: 0 0 13.5% !important;
                margin-left: 0.72857%;
                position: relative;
                abbr {
                  position: absolute;
                  top: 40%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .react-calendar__month-view__days__day--weekend {
              color: black;
            }
            .react-calendar__tile {
              padding-top: 11%;
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .available {
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now {
              background-color: rgba(255, 159, 104, 0.3);
              color: rgba(26, 26, 26, 0.61);
              box-sizing: border-box;
              position: relative;
              font-weight: 500;
              transition: 0.22 ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: rgba(26, 26, 26, 0.61);
              position: absolute;
              top: 75%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .react-calendar__tile:disabled {
              background-color: #fff;
              color: rgb(174, 174, 174);
              font-weight: 500;
            }

            .react-calendar__tile--now:enabled:hover,
            .react-calendar__tile--now:enabled:focus {
              background: rgba(255, 159, 104, 0.6);
            }
            .react-calendar__tile--active {
              background: $orange;
              color: white;
            }
            .react-calendar__tile--active:enabled:hover,
            .react-calendar__tile--active:enabled:focus {
              background: $orange;
              font-weight: 600;
            }
          }
        }
        .calendar-timeslots-active {
          width: 30% !important;
        }
        .calendar-timeslots-container {
          width: 0px;
          display: none;
          height: 100%;
          transition: 0.7s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .calendar-timeslots-date-container {
            height: 15%;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;
            .calendar-timeslots-date {
              width: 100%;
              transition: 0.7s ease-in-out;
              margin: 0;
              position: relative;
              overflow: hidden;
              display: inline-block;
              ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
              }
            }
          }

          .timeslots {
            width: 100%;
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            align-items: center;
            margin-bottom: 10px;
            .notimeslot-text {
              text-align: center;
            }
          }
        }
      }
      .appointment-information-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 40px;
        box-sizing: border-box;
        overflow-y: scroll;
        .appointment-information-title {
          font-size: 2.1em;
          font-family: "Inter", sans-serif;
          color: $gray;
          margin: 0;
        }

        .name-inputs {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          .name-input-container {
            display: flex;
            flex-direction: column;
            .input-label {
              font-family: "Inter", sans-serif;
              font-size: $font-sm;
              font-weight: 600;
            }
            .name-input {
              width: 200px;
              height: 20px;
              padding: 10px;
              border-radius: 10px;
              outline: none;
              border: 1px solid rgba(26, 26, 26, 0.61);
              font-size: $font-md;
              margin-top: 10px;
            }
            .error-message {
              color: red;
              font-size: $font-sm;
              font-weight: 600;
              margin-top: 6px;
            }
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .input {
            height: 20px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .moreabout-input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .moreabout-input {
            height: 70px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
        }

        .calendar-services-title {
          font-size: $font-sm;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          margin-top: 15px;
        }
        .calendar-services-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          row-gap: 15px;
        }

        .file-input-container {
          margin-top: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 1px solid rgba(128, 128, 128, 0.316);
          border-bottom: 1px solid rgba(128, 128, 128, 0.316);

          .file-input-label {
            width: fit-content;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            padding: 7px 15px 7px 15px;
            border-radius: 15px;
            background-color: $orange;
            color: #fff;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: $beige;
              color: $orange;
            }
          }
          .file-upload-instruction {
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            color: $gray;
            margin: 0;
            margin-top: 3px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }

          .all-files-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            height: fit-content;
            margin-top: 10px;
            .file-container {
              width: 20%;
              height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              border-radius: 15px;
              overflow: hidden;
              .file-image {
                width: 100%;
                opacity: 0.6;
              }
              .delete {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translate(-100%, 0);
                cursor: pointer;
                svg {
                  transition: 0.3s ease-in-out;
                }
                &:hover svg {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
        .form-button-container {
          display: flex;
          width: 100%;
          justify-content: center;
          .form-button {
            width: 220px;
            height: 50px;
            padding: 10px 20px 10px 20px;
            outline: none;
            border-radius: 30px;
            background-color: #ff9f688c;
            border: none;
            color: #ffffff;
            font-size: $font-md;
            font-weight: 600;
            margin-top: 60px;
            transition: 0.4s ease-in-out;
          }
          .valid {
            background-color: $orange;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// XS

@media (max-width: 575px) {
  .calendar-screen-container {
    width: 100%;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .calendar-screen-title {
      font-size: 4.3em;
      width: 90%;
      margin: 0;
      color: $orange;
      font-family: "Tilt Neon", sans-serif;
      margin-top: 40px;
      text-align: center;
    }
    .calendar-main-container {
      width: 80%;
      display: flex;
      flex-direction: column;
      min-height: 550px;
      border-radius: 25px;
      margin: 50px 0px 50px 0px;
      -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      transition: 0.7s ease-in-out;
      .calendar-description {
        width: 100% !important;
        display: flex;
        position: relative;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // border-right: 1px solid rgba(134, 134, 134, 0.302);
        transition: 0.7s ease-in-out;
        .arrow-container {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid $gray;
          transition: 0.5s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            transition: 0.3s ease-in-out;
            width: 15px;
            height: 15px;
            color: $gray;
          }
          &:hover svg {
            color: $orange;
          }
          &:hover {
            border-color: $orange;
          }
        }

        .description-logo-container {
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          img {
            width: 80%;
          }
        }

        .description-texts-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding-left: 20px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(134, 134, 134, 0.302);
          padding-bottom: 15px;
          .description-domain {
            margin: 0;
            margin-top: 10px;
            font-size: $font-md;
            color: $orange;
            font-family: "Inter", sans-serif;
            font-weight: 600;
          }
          .description-text {
            width: 90%;
            color: $gray;
            margin: 0;
            margin-top: 10px;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            text-align: center;
          }
          .appointment-details {
            width: 95%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 30px;
            height: fit-content;
            margin-top: 20px;
            .detail-container {
              width: fit-content;

              display: flex;
              align-items: center;
              gap: 7px;
              .detail-icon {
                svg {
                  font-size: $font-lg;
                  color: $gray;
                  margin: 0;
                }
              }
              .detail-text {
                font-size: $font-md;
                color: $gray;
                font-family: "Inter", sans-serif;
                margin: 0;
                span {
                  color: $orange;
                }
              }
            }
          }
        }
      }
      .calendar-and-slot-container {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 500px;
        .calendar-active {
          width: 70% !important;
        }
        .calendar-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 0.7s ease-in-out;

          .react-calendar {
            width: 90% !important;
            border: 0px !important;
            padding: 5px;
            box-sizing: border-box;
            .react-calendar__navigation {
              justify-content: space-between;
              :nth-child(2) {
                order: 2;
              }
              :nth-child(3) {
                order: 0;
              }
              :nth-child(4) {
                order: 3;
              }
            }

            .react-calendar__navigation__label {
              flex-grow: 1 !important;
              text-align: start;
              font-size: $font-md;
              font-weight: 400;
              pointer-events: none;
            }
            .react-calendar__navigation__label__labelText {
              font-weight: 600;
              font-size: $font-md;
            }
            .react-calendar__navigation__arrow {
              font-size: 30px;
              color: $orange;
              border-radius: 50%;
              text-align: center;
              width: 40px !important;
              height: 40px !important;
              padding: 0 !important;
              min-width: 0 !important;
            }
            .react-calendar__navigation__arrow:disabled {
              font-size: 30px;
              color: rgba(26, 26, 26, 0.61);
              background-color: #ffffff;
            }
            .react-calendar__navigation__arrow:hover {
              background: rgba(255, 159, 104, 0.3) !important;
              transition: 0.22s ease-in-out;
            }
            .react-calendar__navigation__arrow:disabled:hover {
              background: #ffffff !important;
              transition: 0.22s ease-in-out;
            }

            .react-calendar__navigation__arrow:enabled:focus {
              outline: none;
            }

            .react-calendar__navigation__arrow:focus {
              outline: none;
              background-color: #fff;
            }
            .react-calendar__navigation__prev2-button {
              display: none;
            }
            .react-calendar__navigation__next2-button {
              display: none;
            }
            .react-calendar__month-view__weekdays {
              text-align: center;
              text-transform: uppercase;
              font-weight: 500;
              font-size: 12px;
              color: rgba(26, 26, 26, 0.61);
              abbr {
                text-decoration: none;
              }
            }
            .react-calendar__month-view__days {
              * {
                margin-top: 5px;
              }
              .react-calendar__month-view__days__day {
                flex: 0 0 13.5% !important;
                margin-left: 0.72857%;
                position: relative;
                abbr {
                  position: absolute;
                  top: 40%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .react-calendar__month-view__days__day--weekend {
              color: black;
            }
            .react-calendar__tile {
              padding-top: 11%;
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .available {
              font-weight: 600;
              font-size: $font-md;
              border-radius: 50%;
              color: $orange;
              background-color: rgba(255, 159, 104, 0.1);
              transition: 0.22s ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now {
              background-color: rgba(255, 159, 104, 0.3);
              color: rgba(26, 26, 26, 0.61);
              box-sizing: border-box;
              position: relative;
              font-weight: 500;
              transition: 0.22 ease-in-out;
              &:hover {
                background-color: rgba(255, 159, 104, 0.6);
              }
            }
            .react-calendar__tile--now::before {
              content: "";
              display: block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: rgba(26, 26, 26, 0.61);
              position: absolute;
              top: 75%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .react-calendar__tile:disabled {
              background-color: #fff;
              color: rgb(174, 174, 174);
              font-weight: 500;
            }

            .react-calendar__tile--now:enabled:hover,
            .react-calendar__tile--now:enabled:focus {
              background: rgba(255, 159, 104, 0.6);
            }
            .react-calendar__tile--active {
              background: $orange;
              color: white;
            }
            .react-calendar__tile--active:enabled:hover,
            .react-calendar__tile--active:enabled:focus {
              background: $orange;
              font-weight: 600;
            }
          }
        }
        .calendar-timeslots-active {
          width: 30% !important;
        }
        .calendar-timeslots-container {
          width: 0px;
          display: none;
          height: 100%;
          transition: 0.7s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .calendar-timeslots-date-container {
            height: 15%;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;
            .calendar-timeslots-date {
              width: 100%;
              transition: 0.7s ease-in-out;
              margin: 0;
              position: relative;
              overflow: hidden;
              display: inline-block;
              ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
              }
            }
          }

          .timeslots {
            width: 100%;
            height: 85%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            align-items: center;
            margin-bottom: 10px;
            .notimeslot-text {
              text-align: center;
            }
          }
        }
      }
      .appointment-information-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 40px;
        box-sizing: border-box;
        overflow-y: scroll;
        .appointment-information-title {
          font-size: 2.1em;
          font-family: "Inter", sans-serif;
          color: $gray;
          margin: 0;
        }

        .name-inputs {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          .name-input-container {
            display: flex;
            flex-direction: column;
            .input-label {
              font-family: "Inter", sans-serif;
              font-size: $font-sm;
              font-weight: 600;
            }
            .name-input {
              width: 90%;
              height: 20px;
              padding: 10px;
              border-radius: 10px;
              outline: none;
              border: 1px solid rgba(26, 26, 26, 0.61);
              font-size: $font-md;
              margin-top: 10px;
            }
            .error-message {
              color: red;
              font-size: $font-sm;
              font-weight: 600;
              margin-top: 6px;
            }
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .input {
            width: 90%;
            height: 20px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }
        }
        .moreabout-input-container {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .input-label {
            font-family: "Inter", sans-serif;
            font-size: $font-sm;
            font-weight: 600;
          }
          .moreabout-input {
            height: 70px;
            padding: 10px;
            font-size: $font-md;
            outline: none;
            border-radius: 10px;
            border: 1px solid rgba(26, 26, 26, 0.61);
            margin-top: 10px;
          }
        }

        .calendar-services-title {
          font-size: $font-sm;
          font-weight: 600;
          font-family: "Inter", sans-serif;
          margin-top: 15px;
        }
        .calendar-services-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          row-gap: 15px;
        }

        .file-input-container {
          margin-top: 30px;
          padding-top: 15px;
          padding-bottom: 15px;
          display: flex;
          flex-direction: column;
          width: 100%;
          border-top: 1px solid rgba(128, 128, 128, 0.316);
          border-bottom: 1px solid rgba(128, 128, 128, 0.316);

          .file-input-label {
            width: fit-content;
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            padding: 7px 15px 7px 15px;
            border-radius: 15px;
            background-color: $orange;
            color: #fff;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: $beige;
              color: $orange;
            }
          }
          .file-upload-instruction {
            font-size: $font-sm;
            font-family: "Inter", sans-serif;
            color: $gray;
            margin: 0;
            margin-top: 3px;
          }
          .error-message {
            color: red;
            font-size: $font-sm;
            font-weight: 600;
            margin-top: 6px;
          }

          .all-files-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            height: fit-content;
            margin-top: 10px;
            .file-container {
              width: 20%;
              height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              border-radius: 15px;
              overflow: hidden;
              .file-image {
                width: 100%;
                opacity: 0.6;
              }
              .delete {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translate(-100%, 0);
                cursor: pointer;
                svg {
                  transition: 0.3s ease-in-out;
                }
                &:hover svg {
                  transform: scale(1.1);
                }
              }
              .error-message {
                color: red;
                font-size: $font-sm;
                font-weight: 600;
                margin-top: 6px;
              }
            }
          }
        }

        .form-button-container {
          display: flex;
          width: 100%;
          justify-content: center;
          .form-button {
            width: 220px;
            height: 50px;
            padding: 10px 20px 10px 20px;
            outline: none;
            border-radius: 30px;
            background-color: #ff9f688c;
            border: none;
            color: #ffffff;
            font-size: $font-md;
            font-weight: 600;
            margin-top: 60px;
            transition: 0.4s ease-in-out;
          }
          .valid {
            background-color: $orange;
            cursor: pointer;
          }
        }
      }
    }
  }
}
