@import "../../styles/variables";

.servicetag-container {
  width: fit-content;
  padding: 8px 15px 8px 15px;
  border: 1px solid $orange;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  .servicetag-name {
    color: $orange;
    margin: 0px;
    font-family: "Inter", sans-serif;
    font-size: $font-sm;
  }

  &:hover {
    background-color: $beige;
    border-color: $beige;
  }
}

.servicetag-active {
  background-color: $orange;
  .servicetag-name {
    color: #fff;
  }
  &:hover {
    background-color: $orange !important;
  }
}

.disabled {
  background-color: $gray;
  border-color: $gray;
  pointer-events: none;
  .servicetag-name {
    color: #fff;
  }
}
