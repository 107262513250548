@import "../../styles/variables";

//////////////////////////////////////// XL

@media (min-width: 1300px) {
  .about-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-title {
      color: $orange;
      font-size: 4.3em;
      margin: 50px 0 0 0;
      font-family: "Tilt Neon", sans-serif;
      font-weight: 600;
    }
    .about-main-text {
      color: $gray;
      text-align: center;
      width: 70%;
      font-size: 1.1em;
      margin: 30px 0 0 0;
      font-family: "Inter", sans-serif;
    }
    .about-sections-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      margin-top: 50px;
      .section-container {
        width: 30%;
        height: 230px;
        display: flex;
        border-radius: 40px;
        background-color: $beige;
        padding: 20px;
        box-sizing: border-box;
        align-items: flex-start;
        .section-number {
          font-size: 3.6em;
          font-family: "Tilt Neon", sans-serif;
          font-weight: 600;
          color: $orange;
          margin: 0;
        }
        .section-text {
          color: $gray;
          font-size: $font-md;
          font-family: "Inter", sans-serif;
          margin: 22px 0 0 15px;

          span {
            font-size: $font-lg;
            font-family: "Tilt Neon", sans-serif;
            color: $orange;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// LG

@media (min-width: 1150px) and (max-width: 1299px) {
  .about-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-title {
      color: $orange;
      font-size: 4.3em;
      margin: 50px 0 0 0;
      font-family: "Tilt Neon", sans-serif;
      font-weight: 600;
    }
    .about-main-text {
      color: $gray;
      text-align: center;
      width: 70%;
      font-size: 1.1em;
      margin: 30px 0 0 0;
      font-family: "Inter", sans-serif;
    }
    .about-sections-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      margin-top: 50px;
      .section-container {
        width: 30%;
        height: 270px;
        display: flex;
        border-radius: 40px;
        background-color: $beige;
        padding: 20px;
        box-sizing: border-box;
        align-items: flex-start;
        .section-number {
          font-size: 3.6em;
          font-family: "Tilt Neon", sans-serif;
          font-weight: 600;
          color: $orange;
          margin: 0;
        }
        .section-text {
          color: $gray;
          font-size: $font-md;
          font-family: "Inter", sans-serif;
          margin: 22px 0 0 15px;

          span {
            font-size: $font-lg;
            font-family: "Tilt Neon", sans-serif;
            color: $orange;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// MD

@media (min-width: 1025px) and (max-width: 1149px) {
  .about-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-title {
      color: $orange;
      font-size: 4.3em;
      margin: 50px 0 0 0;
      font-family: "Tilt Neon", sans-serif;
      font-weight: 600;
    }
    .about-main-text {
      color: $gray;
      text-align: center;
      width: 70%;
      font-size: 1.1em;
      margin: 30px 0 0 0;
      font-family: "Inter", sans-serif;
    }
    .about-sections-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      margin-top: 50px;
      .section-container {
        width: 40%;
        height: 230px;
        display: flex;
        border-radius: 40px;
        background-color: $beige;
        padding: 20px;
        box-sizing: border-box;
        align-items: flex-start;
        .section-number {
          font-size: 3.6em;
          font-family: "Tilt Neon", sans-serif;
          font-weight: 600;
          color: $orange;
          margin: 0;
        }
        .section-text {
          color: $gray;
          font-size: $font-md;
          font-family: "Inter", sans-serif;
          margin: 22px 0 0 15px;

          span {
            font-size: $font-lg;
            font-family: "Tilt Neon", sans-serif;
            color: $orange;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// SM

@media (min-width: 768px) and (max-width: 1024px) {
  .about-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-title {
      color: $orange;
      font-size: 4.3em;
      margin: 50px 0 0 0;
      font-family: "Tilt Neon", sans-serif;
      font-weight: 600;
    }
    .about-main-text {
      color: $gray;
      text-align: center;
      width: 70%;
      font-size: 1.1em;
      margin: 30px 0 0 0;
      font-family: "Inter", sans-serif;
    }
    .about-sections-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      margin-top: 50px;
      .section-container {
        width: 40%;
        display: flex;
        border-radius: 40px;
        background-color: $beige;
        padding: 20px;
        box-sizing: border-box;
        align-items: flex-start;
        .section-number {
          font-size: 3.6em;
          font-family: "Tilt Neon", sans-serif;
          font-weight: 600;
          color: $orange;
          margin: 0;
        }
        .section-text {
          color: $gray;
          font-size: $font-md;
          font-family: "Inter", sans-serif;
          margin: 22px 0 0 15px;

          span {
            font-size: $font-lg;
            font-family: "Tilt Neon", sans-serif;
            color: $orange;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// XS

@media (min-width: 576px) and (max-width: 767px) {
  .about-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-title {
      color: $orange;
      font-size: 4.3em;
      margin: 50px 0 0 0;
      font-family: "Tilt Neon", sans-serif;
      font-weight: 600;
    }
    .about-main-text {
      color: $gray;
      text-align: center;
      width: 70%;
      font-size: 1.1em;
      margin: 30px 0 0 0;
      font-family: "Inter", sans-serif;
    }
    .about-sections-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      margin-top: 50px;
      .section-container {
        width: 70%;
        display: flex;
        border-radius: 40px;
        background-color: $beige;
        padding: 20px;
        box-sizing: border-box;
        align-items: flex-start;
        .section-number {
          font-size: 3.6em;
          font-family: "Tilt Neon", sans-serif;
          font-weight: 600;
          color: $orange;
          margin: 0;
        }
        .section-text {
          color: $gray;
          font-size: $font-md;
          font-family: "Inter", sans-serif;
          margin: 22px 0 0 15px;

          span {
            font-size: $font-lg;
            font-family: "Tilt Neon", sans-serif;
            color: $orange;
          }
        }
      }
    }
  }
}

//////////////////////////////////////// XS

@media (max-width: 575px) {
  .about-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-title {
      color: $orange;
      font-size: 4.3em;
      margin: 50px 0 0 0;
      font-family: "Tilt Neon", sans-serif;
      font-weight: 600;
    }
    .about-main-text {
      color: $gray;
      text-align: center;
      width: 70%;
      font-size: 1.1em;
      margin: 30px 0 0 0;
      font-family: "Inter", sans-serif;
    }
    .about-sections-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-evenly;
      margin-top: 50px;
      .section-container {
        width: 90%;
        display: flex;
        border-radius: 40px;
        background-color: $beige;
        padding: 20px;
        box-sizing: border-box;
        align-items: flex-start;
        .section-number {
          font-size: 3.6em;
          font-family: "Tilt Neon", sans-serif;
          font-weight: 600;
          color: $orange;
          margin: 0;
        }
        .section-text {
          color: $gray;
          font-size: $font-md;
          font-family: "Inter", sans-serif;
          margin: 22px 0 0 15px;

          span {
            font-size: $font-lg;
            font-family: "Tilt Neon", sans-serif;
            color: $orange;
          }
        }
      }
    }
  }
}
