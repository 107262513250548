@import "../../styles/variables";

.servicecard-container {
  width: 360px;
  height: 470px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 30px;
  background-color: $beige;

  .icon-container {
    svg {
      transition: color 300ms;
      width: 300px;
    }
  }
  .servicecard-title {
    font-size: 2.3em;
    color: $orange;
    font-family: "Tilt Neon", sans-serif;
    text-align: center;
    margin: 0;
  }
  .servicecard-description {
    font-size: $font-md;
    color: $gray;
    font-family: "Inter", sans-serif;
    text-align: center;
    margin: 0;
    margin-top: 15px;
  }
  .servicecard-price {
    margin: 0;
    color: $gray;
    font-size: 1.3em;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    margin-top: 15px;

    .span-currency {
      font-size: $font-sm;
      color: $orange;
      font-weight: 600;
    }
    .span-price {
      font-size: 1.5em;
      font-weight: 700;
      color: $orange;
    }
  }
  .servicecard-button {
    width: 170px;
    padding: 15px 16px 15px 16px;
    text-align: center;
    background-color: #fff;
    border-radius: 30px;
    margin-top: 20px;
    p {
      margin: 0;
      font-size: $font-md;
      font-family: "Inter", sans-serif;
      font-weight: 500;
      background: rgb(255, 117, 37);
      background: linear-gradient(
        131deg,
        rgba(255, 117, 37, 1) 19%,
        rgba(255, 159, 104, 1) 52%,
        rgba(255, 178, 133, 1) 90%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    transition: ease-in-out 0.4s;
    &:hover {
      cursor: pointer;
      background-color: $orange;
      p {
        background: rgb(252, 251, 250);
        background: linear-gradient(
          131deg,
          rgb(250, 249, 249) 19%,
          rgb(243, 239, 237) 52%,
          rgb(247, 238, 233) 90%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
