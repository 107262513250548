@import "../../styles/variables";

@media (min-width: 1300px) {
  .footer-main-container {
    width: 100%;
    min-height: 300px;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );
    footer {
      width: 100%;
      padding: 0px 40px 0px 40px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .footer-left {
        width: 30%;
        display: flex;
        flex-direction: column;
        .logo-container {
          width: 100%;
          overflow: hidden;
          border-radius: 30px;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 10px;
          img {
            width: 100%;
          }
        }
        .footer-slogan {
          font-size: $font-md;
          font-weight: 600;
          color: #fff;
          margin: 0;
          margin-top: 10px;
          font-family: "Tilt Neon", sans-serif;
          margin-left: 20px;
        }
        .copyright {
          font-size: $font-sm;
          font-weight: 600;
          color: $beige;
          font-family: "Inter", sans-serif;
          margin-top: 100px;
        }
      }

      .footer-right {
        display: flex;
        column-gap: 100px;

        .quick-links {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .quick-link-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .link {
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            cursor: pointer;
            &:hover {
              color: $beige;
              font-weight: 600;
            }
          }
        }

        .contact-us {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .contact-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .phone {
            display: flex;
            align-items: center;
            gap: 7px;
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            svg {
              font-size: $font-lg !important;
            }
          }
        }
      }
    }
  }
}

//////////////////////////////////////// XL

@media (min-width: 900px) and (max-width: 1299px) {
  .footer-main-container {
    width: 100%;
    min-height: 300px;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );
    footer {
      width: 100%;
      padding: 0px 10px 0px 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .footer-left {
        width: 40%;
        display: flex;
        flex-direction: column;
        .logo-container {
          width: 100%;
          overflow: hidden;
          border-radius: 30px;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 10px;
          img {
            width: 100%;
          }
        }
        .footer-slogan {
          font-size: $font-md;
          font-weight: 600;
          color: #fff;
          margin: 0;
          margin-top: 10px;
          font-family: "Tilt Neon", sans-serif;
          margin-left: 20px;
        }
        .copyright {
          font-size: $font-sm;
          font-weight: 600;
          color: $beige;
          font-family: "Inter", sans-serif;
          margin-top: 100px;
        }
      }

      .footer-right {
        display: flex;
        column-gap: 100px;

        .quick-links {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .quick-link-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .link {
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            cursor: pointer;
            &:hover {
              color: $beige;
              font-weight: 600;
            }
          }
        }

        .contact-us {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .contact-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .phone {
            display: flex;
            align-items: center;
            gap: 7px;
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            svg {
              font-size: $font-lg !important;
            }
          }
        }
      }
    }
  }
}

//////////////////////////////////////// XS

@media (min-width: 576px) and (max-width: 899px) {
  .footer-main-container {
    width: 100%;
    min-height: 300px;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );
    footer {
      width: 100%;
      padding: 0px 40px 0px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column-reverse;
      row-gap: 20px;
      align-items: center;

      .footer-left {
        width: 90%;
        display: flex;
        flex-direction: column;
        .logo-container {
          width: 100%;
          overflow: hidden;
          border-radius: 30px;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 10px;
          img {
            width: 100%;
          }
        }
        .footer-slogan {
          font-size: $font-md;
          font-weight: 600;
          color: #fff;
          margin: 0;
          margin-top: 10px;
          font-family: "Tilt Neon", sans-serif;
          margin-left: 20px;
        }
        .copyright {
          font-size: $font-sm;
          font-weight: 600;
          color: $beige;
          font-family: "Inter", sans-serif;
          margin-top: 100px;
        }
      }

      .footer-right {
        width: 100%;
        display: flex;
        column-gap: 100px;
        justify-content: center;

        .quick-links {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .quick-link-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .link {
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            cursor: pointer;
            &:hover {
              color: $beige;
              font-weight: 600;
            }
          }
        }

        .contact-us {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .contact-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .phone {
            display: flex;
            align-items: center;
            gap: 7px;
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            svg {
              font-size: $font-lg !important;
            }
          }
        }
      }
    }
  }
}

//////////////////////////////////////// XS

@media (max-width: 575px) {
  .footer-main-container {
    width: 100%;
    min-height: 300px;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );
    footer {
      width: 100%;
      padding: 0px 40px 0px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column-reverse;
      row-gap: 20px;
      align-items: center;

      .footer-left {
        width: 90%;
        display: flex;
        flex-direction: column;
        .logo-container {
          width: 100%;
          overflow: hidden;
          border-radius: 30px;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 10px;
          img {
            width: 100%;
          }
        }
        .footer-slogan {
          font-size: $font-md;
          font-weight: 600;
          color: #fff;
          margin: 0;
          margin-top: 10px;
          font-family: "Tilt Neon", sans-serif;
          margin-left: 20px;
        }
        .copyright {
          font-size: $font-sm;
          font-weight: 600;
          color: $beige;
          font-family: "Inter", sans-serif;
          margin-top: 100px;
        }
      }

      .footer-right {
        width: 100%;
        display: flex;
        justify-content: center;

        .quick-links {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .quick-link-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .link {
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            cursor: pointer;
            &:hover {
              color: $beige;
              font-weight: 600;
            }
          }
        }

        .contact-us {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          .contact-title {
            font-size: $font-lg;
            font-weight: 600;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            margin: 0;
            margin-bottom: 20px;
          }
          .phone {
            display: flex;
            align-items: center;
            gap: 7px;
            font-size: $font-md;
            color: #fff;
            font-weight: 500;
            font-family: "Inter", sans-serif;
            svg {
              font-size: $font-lg !important;
            }
          }
        }
      }
    }
  }
}
