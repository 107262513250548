@import "../../styles/variables";

//////////////////////////////////////// XL

@media (min-width: 1300px) {
  .getstarted-global-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );

    .top-curve {
      width: 100%;
      z-index: 2;
    }
    .getstarted-main-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .getstarted-title {
        font-size: 4.3em;
        font-family: "Tilt Neon", sans-serif;
        margin: 0;
        color: #fff;
      }

      .first-section {
        width: 100%;
        padding: 80px 40px 0 40px;
        display: flex;
        box-sizing: border-box;
        .left-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 70%;
            border-radius: 20px;
          }
        }
        .right-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }
      }

      .second-section {
        width: 100%;
        padding: 80px 40px 0 40px;
        box-sizing: border-box;
        display: flex;

        .left-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }

        .right-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 70%;
            border-radius: 20px;
          }
        }
      }
    }
    .bottom-curve {
      width: 100%;
      display: flex;
      align-items: flex-end;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// LG

@media (min-width: 1150px) and (max-width: 1299px) {
  .getstarted-global-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );

    .top-curve {
      width: 100%;
      z-index: 2;
    }
    .getstarted-main-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .getstarted-title {
        font-size: 4.3em;
        font-family: "Tilt Neon", sans-serif;
        margin: 0;
        color: #fff;
      }

      .first-section {
        width: 100%;
        padding: 80px 40px 0 40px;
        display: flex;
        box-sizing: border-box;
        .left-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 70%;
            border-radius: 20px;
          }
        }
        .right-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }
      }

      .second-section {
        width: 100%;
        padding: 80px 40px 0 40px;
        box-sizing: border-box;
        display: flex;

        .left-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }

        .right-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 70%;
            border-radius: 20px;
          }
        }
      }
    }
    .bottom-curve {
      width: 100%;
      display: flex;
      align-items: flex-end;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// MD

@media (min-width: 1025px) and (max-width: 1149px) {
  .getstarted-global-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );

    .top-curve {
      width: 100%;
      z-index: 2;
    }
    .getstarted-main-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .getstarted-title {
        font-size: 4.3em;
        font-family: "Tilt Neon", sans-serif;
        margin: 0;
        color: #fff;
      }

      .first-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        display: flex;
        box-sizing: border-box;
        .left-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 85%;
            border-radius: 20px;
          }
        }
        .right-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }
      }

      .second-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        box-sizing: border-box;
        display: flex;

        .left-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }

        .right-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 85%;
            border-radius: 20px;
          }
        }
      }
    }
    .bottom-curve {
      width: 100%;
      display: flex;
      align-items: flex-end;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// SM

@media (min-width: 768px) and (max-width: 1024px) {
  .getstarted-global-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );

    .top-curve {
      width: 100%;
      z-index: 2;
    }
    .getstarted-main-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .getstarted-title {
        font-size: 4.3em;
        font-family: "Tilt Neon", sans-serif;
        margin: 0;
        color: #fff;
      }

      .first-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        display: flex;
        box-sizing: border-box;
        .left-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 85%;
            border-radius: 20px;
          }
        }
        .right-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }
      }

      .second-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        box-sizing: border-box;
        display: flex;

        .left-container {
          width: 50%;
          padding: 0 40px 0 0;
          display: flex;
          flex-direction: column;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
          }
          ul {
            li {
              color: #fff;
              margin-top: 16px;
            }
          }
        }

        .right-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 85%;
            border-radius: 20px;
          }
        }
      }
    }
    .bottom-curve {
      width: 100%;
      display: flex;
      align-items: flex-end;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// XS

@media (min-width: 576px) and (max-width: 767px) {
  .getstarted-global-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );

    .top-curve {
      width: 100%;
      z-index: 2;
    }
    .getstarted-main-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .getstarted-title {
        font-size: 4.3em;
        font-family: "Tilt Neon", sans-serif;
        margin: 0;
        color: #fff;
      }

      .first-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        .left-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 50%;
            border-radius: 20px;
          }
        }
        .right-container {
          width: 90%;
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            text-align: center;
          }
          ul {
            padding: 0;
            li {
              color: #fff;
              margin-top: 16px;
              text-align: center;
            }
          }
        }
      }

      .second-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .left-container {
          width: 90%;
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            text-align: center;
          }
          ul {
            padding: 0;
            li {
              color: #fff;
              margin-top: 16px;
              text-align: center;
            }
          }
        }

        .right-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 50%;
            border-radius: 20px;
          }
        }
      }
    }
    .bottom-curve {
      width: 100%;
      display: flex;
      align-items: flex-end;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// XS

@media (max-width: 575px) {
  .getstarted-global-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 178, 133, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 117, 37, 1) 90%
    );

    .top-curve {
      width: 100%;
      z-index: 2;
    }
    .getstarted-main-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .getstarted-title {
        font-size: 4.3em;
        font-family: "Tilt Neon", sans-serif;
        margin: 0;
        color: #fff;
        text-align: center;
      }

      .first-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        .left-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 80%;
            border-radius: 20px;
          }
        }
        .right-container {
          width: 90%;
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            text-align: center;
          }
          ul {
            padding: 0;
            li {
              color: #fff;
              margin-top: 16px;
              text-align: center;
            }
          }
        }
      }

      .second-section {
        width: 100%;
        padding: 80px 20px 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .left-container {
          width: 90%;
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .text-title {
            font-size: 2.6em;
            margin: 0;
            color: $beige;
            font-family: "Tilt Neon", sans-serif;
            text-align: center;
          }
          ul {
            padding: 0;
            li {
              color: #fff;
              margin-top: 16px;
              text-align: center;
            }
          }
        }

        .right-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: translateZ(0);
            width: 80%;
            border-radius: 20px;
          }
        }
      }
    }
    .bottom-curve {
      width: 100%;
      display: flex;
      align-items: flex-end;
      z-index: 2;
    }
  }
}
