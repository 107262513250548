@import "../../styles/variables";

//////////////////////////////////////// XL

@media (min-width: 1300px) {
  .home-main-container {
    width: 100%;
    position: relative;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 117, 37, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 178, 133, 1) 90%
    );

    .home-sections-container {
      width: 100%;
      padding: 0px 80px 0px 80px;
      box-sizing: border-box;
      display: flex;
      .left-section {
        width: 50%;

        .left-heading {
          color: white;
          font-size: 4.3em;
          margin: 0;
          margin-top: 150px;
          font-family: "Tilt Neon", sans-serif;
          span {
            font-weight: 600;
          }
        }
        .left-text1 {
          color: white;
          width: 80%;
          font-family: "Inter", sans-serif;
          margin: 0;
          margin-top: 10px;
          font-size: $font-md;
        }
        .booking-button {
          width: 170px;
          padding: 7px 10px 7px 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 30px;
          margin-top: 40px;
          margin-bottom: 60px;
          .button-text {
            margin: 0;
            font-size: $font-md;
            font-family: "Inter", sans-serif;
            font-weight: 500;
            background: rgb(255, 117, 37);
            background: linear-gradient(
              131deg,
              rgba(255, 117, 37, 1) 19%,
              rgba(255, 159, 104, 1) 52%,
              rgba(255, 178, 133, 1) 90%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          transition: ease-in-out 0.4s;
          &:hover {
            cursor: pointer;
            background-color: $orange;
            .button-text {
              background: rgb(252, 251, 250);
              background: linear-gradient(
                131deg,
                rgb(250, 249, 249) 19%,
                rgb(243, 239, 237) 52%,
                rgb(247, 238, 233) 90%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .right-section {
        width: 50%;
        position: relative;
        img {
          transform: translateZ(0);
          width: 80%;
          position: absolute;
          top: 10%;
          left: 20%;
        }
      }
    }

    .bottom-curve {
      width: 100%;
      display: flex;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// LG

@media (min-width: 1150px) and (max-width: 1299px) {
  .home-main-container {
    width: 100%;
    position: relative;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 117, 37, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 178, 133, 1) 90%
    );

    .home-sections-container {
      width: 100%;
      padding: 0px 80px 0px 80px;
      box-sizing: border-box;
      display: flex;
      .left-section {
        width: 50%;

        .left-heading {
          color: white;
          font-size: 4.3em;
          margin: 0;
          margin-top: 80px;
          font-family: "Tilt Neon", sans-serif;
          span {
            font-weight: 600;
          }
        }
        .left-text1 {
          color: white;
          font-family: "Inter", sans-serif;
          margin: 0;
          margin-top: 10px;
          font-size: $font-md;
        }
        .booking-button {
          width: 170px;
          padding: 7px 10px 7px 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 30px;
          margin-top: 40px;
          .button-text {
            margin: 0;
            font-size: $font-md;
            font-family: "Inter", sans-serif;
            font-weight: 500;
            background: rgb(255, 117, 37);
            background: linear-gradient(
              131deg,
              rgba(255, 117, 37, 1) 19%,
              rgba(255, 159, 104, 1) 52%,
              rgba(255, 178, 133, 1) 90%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          transition: ease-in-out 0.4s;
          &:hover {
            cursor: pointer;
            background-color: $orange;
            .button-text {
              background: rgb(252, 251, 250);
              background: linear-gradient(
                131deg,
                rgb(250, 249, 249) 19%,
                rgb(243, 239, 237) 52%,
                rgb(247, 238, 233) 90%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .right-section {
        width: 50%;
        position: relative;
        img {
          transform: translateZ(0);
          width: 80%;
          position: absolute;
          top: 10%;
          left: 20%;
        }
      }
    }

    .bottom-curve {
      width: 100%;
      display: flex;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// MD

@media (min-width: 1025px) and (max-width: 1149px) {
  .home-main-container {
    width: 100%;
    position: relative;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 117, 37, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 178, 133, 1) 90%
    );

    .home-sections-container {
      width: 100%;
      padding: 0px 80px 0px 80px;
      box-sizing: border-box;
      display: flex;
      .left-section {
        width: 50%;

        .left-heading {
          color: white;
          font-size: 4.3em;
          margin: 0;
          margin-top: 80px;
          font-family: "Tilt Neon", sans-serif;
          span {
            font-weight: 600;
          }
        }
        .left-text1 {
          color: white;
          font-family: "Inter", sans-serif;
          margin: 0;
          margin-top: 10px;
          font-size: $font-md;
        }
        .booking-button {
          width: 170px;
          padding: 7px 10px 7px 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 30px;
          margin-top: 40px;
          .button-text {
            margin: 0;
            font-size: $font-md;
            font-family: "Inter", sans-serif;
            font-weight: 500;
            background: rgb(255, 117, 37);
            background: linear-gradient(
              131deg,
              rgba(255, 117, 37, 1) 19%,
              rgba(255, 159, 104, 1) 52%,
              rgba(255, 178, 133, 1) 90%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          transition: ease-in-out 0.4s;
          &:hover {
            cursor: pointer;
            background-color: $orange;
            .button-text {
              background: rgb(252, 251, 250);
              background: linear-gradient(
                131deg,
                rgb(250, 249, 249) 19%,
                rgb(243, 239, 237) 52%,
                rgb(247, 238, 233) 90%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .right-section {
        width: 50%;
        position: relative;
        img {
          transform: translateZ(0);
          width: 80%;
          position: absolute;
          top: 10%;
          left: 20%;
        }
      }
    }

    .bottom-curve {
      width: 100%;
      display: flex;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// SM

@media (min-width: 768px) and (max-width: 1024px) {
  .home-main-container {
    width: 100%;
    position: relative;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 117, 37, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 178, 133, 1) 90%
    );

    .home-sections-container {
      width: 100%;
      padding: 0px 80px 0px 80px;
      box-sizing: border-box;
      display: flex;
      .left-section {
        width: 50%;

        .left-heading {
          color: white;
          font-size: 4.3em;
          margin: 0;
          margin-top: 80px;
          font-family: "Tilt Neon", sans-serif;
          span {
            font-weight: 600;
          }
        }
        .left-text1 {
          color: white;
          font-family: "Inter", sans-serif;
          margin: 0;
          margin-top: 10px;
          font-size: $font-md;
        }
        .booking-button {
          width: 170px;
          padding: 7px 10px 7px 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 30px;
          margin-top: 40px;
          .button-text {
            margin: 0;
            font-size: $font-md;
            font-family: "Inter", sans-serif;
            font-weight: 500;
            background: rgb(255, 117, 37);
            background: linear-gradient(
              131deg,
              rgba(255, 117, 37, 1) 19%,
              rgba(255, 159, 104, 1) 52%,
              rgba(255, 178, 133, 1) 90%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          transition: ease-in-out 0.4s;
          &:hover {
            cursor: pointer;
            background-color: $orange;
            .button-text {
              background: rgb(252, 251, 250);
              background: linear-gradient(
                131deg,
                rgb(250, 249, 249) 19%,
                rgb(243, 239, 237) 52%,
                rgb(247, 238, 233) 90%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .right-section {
        width: 50%;
        position: relative;
        img {
          transform: translateZ(0);
          width: 90%;
          position: absolute;
          top: 20%;
          left: 20%;
        }
      }
    }

    .bottom-curve {
      width: 100%;
      display: flex;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// XS

@media (min-width: 576px) and (max-width: 767px) {
  .home-main-container {
    width: 100%;
    position: relative;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 117, 37, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 178, 133, 1) 90%
    );

    .home-sections-container {
      width: 100%;
      padding: 0px 80px 0px 80px;
      box-sizing: border-box;
      display: flex;
      .left-section {
        width: 50%;

        .left-heading {
          color: white;
          font-size: 4.3em;
          margin: 0;
          margin-top: 80px;
          font-family: "Tilt Neon", sans-serif;
          span {
            font-weight: 600;
          }
        }
        .left-text1 {
          color: white;
          font-family: "Inter", sans-serif;
          margin: 0;
          margin-top: 10px;
          font-size: $font-md;
        }
        .booking-button {
          width: 170px;
          padding: 7px 10px 7px 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 30px;
          margin-top: 40px;
          .button-text {
            margin: 0;
            font-size: $font-md;
            font-family: "Inter", sans-serif;
            font-weight: 500;
            background: rgb(255, 117, 37);
            background: linear-gradient(
              131deg,
              rgba(255, 117, 37, 1) 19%,
              rgba(255, 159, 104, 1) 52%,
              rgba(255, 178, 133, 1) 90%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          transition: ease-in-out 0.4s;
          &:hover {
            cursor: pointer;
            background-color: $orange;
            .button-text {
              background: rgb(252, 251, 250);
              background: linear-gradient(
                131deg,
                rgb(250, 249, 249) 19%,
                rgb(243, 239, 237) 52%,
                rgb(247, 238, 233) 90%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .right-section {
        width: 50%;
        position: relative;
        img {
          transform: translateZ(0);
          width: 100%;
          position: absolute;
          top: 30%;
          left: 20%;
        }
      }
    }

    .bottom-curve {
      width: 100%;
      display: flex;
      z-index: 2;
    }
  }
}

//////////////////////////////////////// XS

@media (max-width: 575px) {
  .home-main-container {
    width: 100%;
    position: relative;
    z-index: 1;
    background: rgb(255, 117, 37);
    background: linear-gradient(
      131deg,
      rgba(255, 117, 37, 1) 19%,
      rgba(255, 159, 104, 1) 52%,
      rgba(255, 178, 133, 1) 90%
    );

    .home-sections-container {
      width: 100%;
      padding: 0px 20px 0px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column-reverse;
      .left-section {
        width: 100%;
        z-index: 2;

        .left-heading {
          color: white;
          font-size: 4.3em;
          margin: 0;
          margin-top: 80px;
          font-family: "Tilt Neon", sans-serif;
          span {
            font-weight: 600;
          }
        }
        .left-text1 {
          color: white;
          font-family: "Inter", sans-serif;
          margin: 0;
          margin-top: 10px;
          font-size: $font-md;
        }
        .booking-button {
          width: 170px;
          padding: 7px 10px 7px 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 30px;
          margin-top: 40px;
          .button-text {
            margin: 0;
            font-size: $font-md;
            font-family: "Inter", sans-serif;
            font-weight: 500;
            background: rgb(255, 117, 37);
            background: linear-gradient(
              131deg,
              rgba(255, 117, 37, 1) 19%,
              rgba(255, 159, 104, 1) 52%,
              rgba(255, 178, 133, 1) 90%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          transition: ease-in-out 0.4s;
          &:hover {
            cursor: pointer;
            background-color: $orange;
            .button-text {
              background: rgb(252, 251, 250);
              background: linear-gradient(
                131deg,
                rgb(250, 249, 249) 19%,
                rgb(243, 239, 237) 52%,
                rgb(247, 238, 233) 90%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .right-section {
        width: 100%;
        position: relative;
        z-index: 1;
        img {
          transform: translateZ(0);
          width: 70%;
          position: absolute;
          top: 30%;
          left: 40%;
        }
      }
    }

    .bottom-curve {
      width: 100%;
      display: flex;
      z-index: 2;
    }
  }
}
