@import "../../styles/variables";

.timeslot-container {
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .timeslot {
    border-radius: 10px;
    border: 1px solid #ff9f688c;
    cursor: pointer;
    transition: 0.22s ease-in-out;

    &:hover {
      border: 1px solid $orange;
    }
    .timeslot-text {
      text-align: center;
      font-size: $font-sm;
      font-weight: 600;
      color: $orange;
    }
  }

  .confirm-container {
    width: 0px;
    background-color: $orange;
    border-radius: 10px;
    transition: 0.22s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .confirm-text {
      text-align: center;
      font-size: $font-sm;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
