.failed-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: #f44336;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
  
    .close-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  
    .dismiss-button {
      display: block;
      margin: 20px auto 0;
      padding: 10px 20px;
      background-color: #fff;
      color: #f44336;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
  
      &:hover {
        background-color: #f44336;
        color: #fff;
      }
    }
  }
  