@import "../../styles/variables";

.services-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .services-title {
    color: $orange;
    font-size: 4.3em;
    font-family: "Tilt Neon", sans-serif;
    margin: 0;
    text-align: center;
  }
  .services-seeall-button {
    width: 150px;
    height: 40px;
    border-radius: 30px;
    background-color: $beige;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in-out;
    margin-top: 40px;
    cursor: pointer;
    &:hover {
      background-color: $orange;
    }
    &:hover .seeall-text {
      color: $beige;
    }
    .seeall-text {
      font-size: $font-md;
      font-weight: 600;
      letter-spacing: normal;
      font-family: " Inter", sans-serif;
      color: $orange;
    }
  }
}
