// Font Sizes

$font-xs: 0.75em; // 12px
$font-sm: 0.875em; // 14px
$font-md: 1em; //16px
$font-lg: 1.4em; //22.4px
$font-xl: 2em; // 32px

// Colors

$orange: #ff9f68;
$beige: #fbe8e0;
$pink:  #FF8A89;
$gray: #70635b


