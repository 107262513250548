@import "../../styles/variables";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@media (min-width: 1300px) {
  .slider {
    width: 100%;
    margin-top: 60px;
    padding: 0px 40px 0px 40px;
    box-sizing: border-box;
    overflow: visible;
  }
  .slide {
    transform: scale(0.4);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  .activeSlide {
    transform: scale(0.96);
    opacity: 1;
  }

  .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    svg {
      transition: color 300ms;
      &:hover {
        color: $orange;
      }
    }
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }
}

@media (min-width: 1150px) and (max-width: 1299px) {
  .slider {
    width: 100%;
    margin-top: 60px;
    padding: 0px 40px 0px 40px;
    box-sizing: border-box;
    overflow: visible;
  }
  .slide {
    transform: scale(0.4);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  .activeSlide {
    transform: scale(0.96);
    opacity: 1;
  }

  .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    svg {
      transition: color 300ms;
      &:hover {
        color: $orange;
      }
    }
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }
}

@media (min-width: 1025px) and (max-width: 1149px) {
  .slider {
    width: 100%;
    margin-top: 60px;
    padding: 0px 40px 0px 40px;
    box-sizing: border-box;
    overflow: visible;
  }
  .slide {
    transform: scale(0.4);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  .activeSlide {
    transform: scale(0.96);
    opacity: 1;
  }

  .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    svg {
      transition: color 300ms;
      &:hover {
        color: $orange;
      }
    }
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .slider {
    width: 100%;
    margin-top: 60px;
    padding: 0px 40px 0px 40px;
    box-sizing: border-box;
    overflow: visible;
  }
  .slide {
    transform: scale(0.4);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  .activeSlide {
    transform: scale(0.96);
    opacity: 1;
  }

  .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    svg {
      transition: color 300ms;
      &:hover {
        color: $orange;
      }
    }
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .slider {
    width: 100%;
    margin-top: 60px;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    overflow: visible;
  }
  .slide {
    transform: scale(0.35);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  .activeSlide {
    transform: scale(0.7);
    opacity: 1;
  }

  .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    svg {
      transition: color 300ms;
      &:hover {
        color: $orange;
      }
    }
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }
}

@media (max-width: 575px) {
  .slider {
    width: 100%;
    margin-top: 60px;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    overflow: visible;
  }
  .slide {
    transform: scale(0.35);
    transition: transform 300ms;
    opacity: 0.3;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  .activeSlide {
    transform: scale(0.7);
    opacity: 1;
  }

  .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    svg {
      transition: color 300ms;
      &:hover {
        color: $orange;
      }
    }
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }
}
// .slider {
//   width: 100%;
//   margin-top: 60px;
//   padding: 0px 40px 0px 40px;
//   box-sizing: border-box;
//   overflow: visible;
// }
// .slide {
//   transform: scale(0.4);
//   transition: transform 300ms;
//   opacity: 0.5;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
//   img {
//     width: 200px;
//     margin: 0 auto;
//   }
// }

// .activeSlide {
//   transform: scale(0.96);
//   opacity: 1;
// }

// .arrow {
//   position: absolute;
//   cursor: pointer;
//   z-index: 10;
//   svg {
//     transition: color 300ms;
//     &:hover {
//       color: $orange;
//     }
//   }
// }

// .next {
//   right: 0%;
//   top: 50%;
// }

// .prev {
//   left: 0%;
//   top: 50%;
// }
